@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

.product-table-wrapper {
    padding: 50px 10px 65px 10px;
    text-align: center;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

.product-table-wrapper .btn-nav {
    color: #cd2653;
    margin: 0;
}

.product-table-wrapper .alert {
    width: fit-content;
    margin: 0 auto;
    min-width: 300px;
}

.product-table-wrapper table {
    margin: 10px 0;
}

.product-table-wrapper h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 38px;
}

.product-table-wrapper h1.product-list-title {
    padding: 45px 0;
}

.product-table-wrapper .product-info .product-details-wrapper {
    margin-bottom: 50px;
}

.product-table-wrapper .product-info .product-details-wrapper .product-detail {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.product-table-wrapper .product-info .product-details-wrapper .product-detail span {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

.product-page-bg-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 345px;
    background: #cd2653;
    z-index: -2;
}

.product-table-wrapper .table-wrapper {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.36);
    margin: 0 auto;
}

.product-table-wrapper .table-wrapper table {
    background-color: #fff;
}

.product-table-wrapper .table-wrapper table  tbody tr {
    color: #000;
}

.product-table-wrapper .table-wrapper .table thead th {
    border-color: #cd2653;
}

.product-table-wrapper .table-wrapper nav {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    border-top: 1px solid #dee2e6;
}

@media only screen and (min-width: 1920px) {

    .product-table-wrapper .table-wrapper {
        width: 80%;
    }
}

@media only screen and (min-width: 1280px) {
    .product-table-wrapper {
        padding: 50px 30px 65px 30px;
    }

    .product-table-wrapper .table-wrapper .table-products-update {
        display: table;
    }

    .product-table-wrapper .btn-nav {
        margin: 20px 20px 20px 0;
    }
}

@media only screen and (min-width: 600px) {

    .product-page-bg-color {
        height: 390px;
    }

    .product-table-wrapper h1 {
        font-size: 88px;
    }
}

@media only screen and (max-width: 599px) {
    .product-table-wrapper .btn-back {
        width: 100%;
    }
}

@media only screen and (min-width: 380px) {
    .product-table-wrapper .table-wrapper .table-product-list {
        display: table;
    }
}

